import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/fishtrap',
      name: 'fishtrap',
      component: () => import('@/views/FishTrapNewView.vue'),
      meta: {
        title: 'FishTrap Game'
      }
    },
    {
      path: '/fishtrap/replay/:shortId',
      name: 'Replay',
      component: () => import('@/views/FishtrapReplayView.vue'),
      meta: {
        title: 'Replay Fishtrap Game'
      }
    },
    {
      path: '/mousetrap',
      name: 'mousetrap',
      component: () => import('@/views/MouseView.vue'),
      meta: {
        title: 'Mouse Trap Game'
      }
    },
    {
      path: '/plinko',
      name: 'plinko',
      component: () => import('@/views/PlinkoView.vue'),
      meta: {
        title: 'Plinko Game'
      }
    },
    {
      path: '/towergame',
      name: 'towergame',
      meta: {
        title: 'Tower Game'
      },
      component: () => import('@/views/TowerGameView.vue')
    },
    {
      path: '/boxgame',
      name: 'boxGame',
      component: () => import('@/views/BoxGameView.vue'),
      meta: {
        title: 'Box Game'
      }
    },
    {
      path: '/showcase',
      name: 'Component Showcase',
      component: () => import('@/views/ShowcaseView.vue'),
      meta: {
        title: 'Balkan Games Component Showcase'
      }
    },
    {
      path: '/',
      name: 'Lobby',
      component: () => import('@/views/LobbyView.vue'),
      meta: {
        title: 'Balkan Games Lobby'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title as string
  next()
})

export default router
