import avatar1 from '@/assets/shared/avatars/avatar1.jpg'
import avatar2 from '@/assets/shared/avatars/avatar2.jpg'
import avatar3 from '@/assets/shared/avatars/avatar3.jpg'
import avatar4 from '@/assets/shared/avatars/avatar4.jpg'
import avatar5 from '@/assets/shared/avatars/avatar5.jpg'
import avatar6 from '@/assets/shared/avatars/avatar6.jpg'
import avatar7 from '@/assets/shared/avatars/avatar7.jpg'
import avatar8 from '@/assets/shared/avatars/avatar8.jpg'
import avatar9 from '@/assets/shared/avatars/avatar9.jpg'
import avatar10 from '@/assets/shared/avatars/avatar10.jpg'
import avatar11 from '@/assets/shared/avatars/avatar11.jpg'
import avatar12 from '@/assets/shared/avatars/avatar12.jpg'
import avatar13 from '@/assets/shared/avatars/avatar13.jpg'
import avatar14 from '@/assets/shared/avatars/avatar14.jpg'
import avatar15 from '@/assets/shared/avatars/avatar15.jpg'
import avatar16 from '@/assets/shared/avatars/avatar16.jpg'
import avatar17 from '@/assets/shared/avatars/avatar17.jpg'
import avatar18 from '@/assets/shared/avatars/avatar18.jpg'
import avatar19 from '@/assets/shared/avatars/avatar19.jpg'
import avatar20 from '@/assets/shared/avatars/avatar20.jpg'
import avatar21 from '@/assets/shared/avatars/avatar21.jpg'
import avatar22 from '@/assets/shared/avatars/avatar22.jpg'
import avatar23 from '@/assets/shared/avatars/avatar23.jpg'
import avatar24 from '@/assets/shared/avatars/avatar24.jpg'

export const appName = window.location.pathname.split('/')[1]
export const prefix = 'balkan-' + appName
export const appSessionIdKey = prefix + '-session-id'

export const local_storage_keys = {
  USER_SETTINGS: prefix + '-user-settings',
  USER_TIME: prefix + '-user-time',
  USER_SEEN_TIME_NOTIFY: prefix + '-time-seen',
}

export const fiatCurrencies = [
  "USD", "EUR", "GBP", "JPY", "CNY", "INR", "CAD", "AUD", "CHF", "SEK",
  "NOK", "NZD", "MXN", "BRL", "ZAR", "KRW", "TRY", "RUB", "HKD", "SGD",
  "DKK", "PLN", "MYR", "IDR", "THB", "PHP", "CZK", "HUF", "AED", "SAR"
];

export const allAvatars = [
  { name: 'avatar1.png', path: avatar1 },
  { name: 'avatar2.png', path: avatar2 },
  { name: 'avatar3.png', path: avatar3 },
  { name: 'avatar4.png', path: avatar4 },
  { name: 'avatar5.png', path: avatar5 },
  { name: 'avatar6.png', path: avatar6 },
  { name: 'avatar7.png', path: avatar7 },
  { name: 'avatar8.png', path: avatar8 },
  { name: 'avatar9.png', path: avatar9 },
  { name: 'avatar10.png', path: avatar10 },
  { name: 'avatar11.png', path: avatar11 },
  { name: 'avatar12.png', path: avatar12 },
  { name: 'avatar13.png', path: avatar13 },
  { name: 'avatar14.png', path: avatar14 },
  { name: 'avatar15.png', path: avatar15 },
  { name: 'avatar16.png', path: avatar16 },
  { name: 'avatar17.png', path: avatar17 },
  { name: 'avatar18.png', path: avatar18 },
  { name: 'avatar19.png', path: avatar19 },
  { name: 'avatar20.png', path: avatar20 },
  { name: 'avatar21.png', path: avatar21 },
  { name: 'avatar22.png', path: avatar22 },
  { name: 'avatar23.png', path: avatar23 },
  { name: 'avatar24.png', path: avatar24 }
]
