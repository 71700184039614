import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

const service = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL as string,
    timeout: 50000
});

/* service.interceptors.request.use(
    (config: AxiosRequestConfig): any => {
        const token: string | undefined = 'testest';
        if (token && config?.headers) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error: AxiosError) => {
        console.log(error);
        return Promise.reject(error);
    }
); */

service.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        let message: string = error?.message;
        if (error?.response?.data) {
            const data = error.response.data;
            // message = (typeof data === 'object' && data !== null && 'message' in data) ? data.message : message;
        }
        return Promise.reject(error);
    }
);

export default service;
